import {
  highlightFirstVideo,
  highlightFourthVideo,
  highlightSecondVideo,
  highlightThirdVideo,
  heroAboutImage,
  aboutFirstImage,
  aboutSecondImage,
  aboutThirdImage,
  CompanyProfile,
  ProductCatalog,
  BusinessProposal,
  costco,
  kuka,
  sleemon,
  samsClub,
  amazon,
} from "../utils";

import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

export const navLists = ["About", "Videos", "FAQ"];

export const hightlightsSlides = [
  {
    id: 1,
    textLists: [
      "Factory direct supply",
      "250+ skilled workers",
      "Quality-assured production",
    ],
    video: highlightFirstVideo,
    videoDuration: 4,
  },
  {
    id: 2,
    textLists: [
      "Market-driven development",
      "100+ patents. ",
      "Rapid prototyping.",
    ],
    video: highlightSecondVideo,
    videoDuration: 5,
  },
  {
    id: 3,
    textLists: [
      "Fastest delivery cycle",
      "Innovative supply chain",
      "One stop warehousing service.",
    ],
    video: highlightThirdVideo,
    videoDuration: 2,
  },
  {
    id: 4,
    textLists: ["Price is good", "Product is excellent."],
    video: highlightFourthVideo,
    videoDuration: 3.63,
  },
];

export const sizes = [
  { label: '6.1"', value: "small" },
  { label: '6.7"', value: "large" },
];

export const footerLinks = [
  { text: "Privacy Policy", href: "https://www.aimycomfort.com/store/privacy-policy" },
  { text: "Sales Policy", href: "https://www.aimycomfort.com/store/sales-policy" },
  { text: "Our Store", href: "https://www.aimycomfort.com/store/" },
  { text: "FAQ", href: "/faq" },
];

export const socialLinks = [
  { name: 'Facebook', url: 'https://www.facebook.com/aimycomfort', icon: faFacebook },
  { name: 'Twitter', url: 'https://x.com/aimycomfort', icon: faTwitter },
  { name: 'Instagram', url: 'https://www.instagram.com', icon: faInstagram },
  { name: 'Youtube', url: 'https://www.youtube.com/channel/UCLHHLSQE8HeFC3g1-CUiu8w', icon: faYoutube }
  // Add more social links as needed
]

export const aboutSections = [
  {
    type: 'hero',
    img: heroAboutImage,
    title: 'About',
    text: 'An integrated polyurethane industry chain enterprise'
  },
  {
    type: 'section',
    img: aboutFirstImage,
    title: 'About us',
    text: 'From ergonomic chairs to premium bedding, we offer items designed to improve daily life. Our development process ensures quick prototyping and production, tailored to market trends and your needs. We are ready to transform our quality products into successful extensions of your brand.',
    pdfLink: CompanyProfile
  },
  {
    type: 'section',
    img: aboutSecondImage,
    title: 'Products Overview',
    text: 'We offer home products especially polyurethane products for home, office supplies, automotive interiors, pet products... Any products you can think of that relieve fatigue and bring comfort, we design it. ',
    pdfLink: ProductCatalog
  },
  {
    type: 'section',
    img: aboutThirdImage,
    title: 'Great Support',
    text: "We proudly partner with renowned brands like Kuka and Sleemon, as well as rising stars such as Cicido, Jago, and Nira in China. Our products grace the shelves of COSTCO and Sam's Club. Whether you're an industry giant or a startup, we offer unwavering support.",
    pdfLink: BusinessProposal
  }
];

// testimonials on about-us page.

export const testimonials = [
  {
    quote:
      "There is almost no odor. The one I bought from another store had a very strong smell, but this one basically has no smell, just a little bit. The seller mentioned that due to the high volume of orders, the products are freshly made, so after receiving the item, you just need to ventilate it for a little while. I strongly recommend this to students. The design is very nice; it’s truly a lunch break essential. You no longer have to worry about your hands hurting from resting on them during naps.",
    name: "Michael Johnson",
    title: "Director of AlphaStream Technologies",
  },
  {
    quote:
      "I am really satisfied with the pillow! The seller has a good reputation, the craftsmanship is meticulous, and their attitude is excellent. The style also matches my taste, and it works great. Highly recommended! The seller is friendly and attentive, the shipping speed is fast, and it was an extremely satisfying shopping experience!",
    name: "Michael Johnson",
    title: "Director of AlphaStream Technologies",
  },
  {
    quote:
      "I received the pillow and used it the same night. Sitting at the office every day has caused some serious neck issues, which I guess is an occupational hazard. The stiffness in my neck was causing dizziness and lightheadedness. The first night using this pillow felt a bit uncomfortable, probably because of my straightened cervical spine, but now I’ve gotten used to it. When lying flat, the recessed part of the pillow holds my head in place, and my neck is well-supported by the middle section. I wake up feeling very comfortable and refreshed! ",
    name: "Michael Johnson",
    title: "Director of AlphaStream Technologies",
  },
  {
    quote:
      "The design of this product looks good, and after trying it, the texture feels quite nice. Firstly, the fabric stitching is very tight, and secondly, there is no noticeable odor, which I am quite satisfied with. The outer layer is removable, making it easy to clean. After placing it on the seat, it provides excellent support for the lower back, making driving much more comfortable. I feel more alert while driving and not as easily fatigued. I think I should have bought this sooner. It’s definitely worth the price",
    name: "Michael Johnson",
    title: "Director of AlphaStream Technologies",
  },
];

export const companies = [
  {
    id: 1,
    name: "costco",
    img: costco,
  },
  {
    id: 2,
    name: "kuka",
    img: kuka,
  },
  {
    id: 3,
    name: "sleemon",
    img: sleemon,
  },
  {
    id: 4,
    name: "sams-club",
    img: samsClub,
  },
  {
    id: 5,
    name: "amazon",
    img: amazon,
  },
];

export const socialMedia = [
  {
    id: 1,
    img: "/git.svg",
  },
  {
    id: 2,
    img: "/twit.svg",
  },
  {
    id: 3,
    img: "/link.svg",
  },
];

export const recentTradeShows = [
  {
    title: 'Trade Show 1',
    date: 'January 15, 2023',
    description: 'Description of Trade Show 1.',
  },
  {
    title: 'Trade Show 2',
    date: 'March 10, 2023',
    description: 'Description of Trade Show 2.',
  },
  // Add more recent trade shows as needed
];

export const futureTradeShows = [
  {
    title: 'Trade Show 3',
    date: 'July 20, 2023',
    description: 'Description of Trade Show 3.',
  },
  {
    title: 'Trade Show 4',
    date: 'October 5, 2023',
    description: 'Description of Trade Show 4.',
  },
  // Add more future trade shows as needed
];



