import React from 'react';
import { aboutSections, companies } from '../constants'; // Import companies
import Clients from './Clients';

const About = () => {
    const heroSection = aboutSections.find(section => section.type === 'hero');
    const contentSections = aboutSections.filter(section => section.type === 'section');

    return (
        <>
            {heroSection && (
                <div className="relative h-[70vh] w-full">
                    <img
                        src={heroSection.img}
                        alt={heroSection.title}
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                        <div className="text-center text-white max-w-4xl px-4">
                            <h1 className="text-4xl font-bold mb-8 text-center">About</h1>
                            <p className="text-base max-w-xl mx-auto">{heroSection.text}</p>
                        </div>
                    </div>
                </div>
            )}

            {/* Companies Section */}
            <div className="flex flex-wrap items-center justify-center gap-4 md:gap-16 py-10">
                {companies.map((company) => (
                    <React.Fragment key={company.id}>
                        <div className="flex md:max-w-60 max-w-32 gap-2">
                            <img
                                src={company.img}
                                alt={company.name}
                                className="h-16 md:h-20 w-auto"  // Fixed height with auto width
                            />
                        </div>
                    </React.Fragment>
                ))}
            </div>

            {/* YouTube Video Section */}
            <div className="flex justify-center py-5">
                <div className="relative w-full" style={{ paddingBottom: '56.25%' /* 16:9 aspect ratio */ }}>
                    <iframe
                        className="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/x1GUneg1ZZk?si=vezjnHqXu4M2e_wn"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>

            {/* Content Sections */}
            <div className="pt-10 pb-2 md:pb-10 mx-auto px-4 screen-max-width">
                <h2 className="text-5xl font-bold pb-14 text-center">AimyComfort Is Leading The Industry</h2>
                {contentSections.map((section, index) => (
                    <div key={index} className={`flex flex-col md:flex-row ${index % 2 === 0 ? '' : 'md:flex-row-reverse'} mb-10`}>
                        <div className="w-full md:w-1/2">
                            <img src={section.img} alt={section.title} className="w-full h-full object-cover" />
                        </div>
                        <div className="w-full md:w-1/2 flex flex-col justify-center p-8">
                            <h3 className="text-3xl font-bold mb-4">{section.title}</h3>
                            <p className="mb-5">{section.text}</p>
                            {section.pdfLink && (
                                <a
                                    href={section.pdfLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        display: 'block',
                                        backgroundColor: '#2997FF',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        textDecoration: 'none',
                                        marginTop: '5px',
                                        width: 'fit-content',
                                        position: 'relative',
                                        zIndex: 9999
                                    }}
                                    onMouseEnter={(e) => e.target.style.backgroundColor = '#2563EB'} // Darker blue on hover (blue-600)
                                    onMouseLeave={(e) => e.target.style.backgroundColor = '#3B82F6'} // Back to original color
                                >
                                    Learn More
                                </a>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <Clients />
        </>
    );
};

export default About;



